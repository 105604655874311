import { useMemo } from 'react';

import {
  FeatureTypeEnum,
  getIntlDefaultLocaleSelector,
  getPlatformInformationPrivateSelector,
  getPlatformInformationPublicSelector,
  LabsEnum,
  LearningLabSettingsEnum,
  LoginTypeEnum,
} from '@mentorcliq/storage';

import { generateUrl } from 'helpers/common';

import { useAppSelector } from 'hooks/useAppSelector';

export const useAppConfigs = () => {
  const platformPublicConfigs = useAppSelector(({ platformInformationPublic }) =>
    getPlatformInformationPublicSelector(platformInformationPublic),
  );
  const platformPrivateConfigs = useAppSelector(({ platformInformationPrivate }) =>
    getPlatformInformationPrivateSelector(platformInformationPrivate),
  );
  const defaultLocale = useAppSelector(({ intl }) => getIntlDefaultLocaleSelector(intl));

  const languages = useMemo(() => {
    const items = platformPublicConfigs?.platformPublicConfiguration?.languages;
    const base = items?.filter((lang) => lang.languageCode === defaultLocale) || [];
    const other = items?.filter((lang) => lang.enabled && lang.languageCode !== defaultLocale) || [];

    const sorted = base
      .concat([...other].sort((a, b) => a.nativeName.localeCompare(b.nativeName)))
      .filter((lang) => lang.enabled);

    return {
      list: sorted,
      locale: sorted[0]?.languageCode || defaultLocale,
      native: defaultLocale,
    };
  }, [platformPublicConfigs?.platformPublicConfiguration?.languages, defaultLocale]);

  const publicConfigs = useMemo(
    () => ({
      features: platformPublicConfigs?.featureStates,
      customerId: platformPublicConfigs?.customerId,
      hrisIssueNoticeMessage: platformPublicConfigs?.hrisIssueNoticeMessage,
      loginInfo: platformPublicConfigs?.loginInfo,
      platformConfigs: platformPublicConfigs?.platformPublicConfiguration,
      platformInfo: platformPublicConfigs?.platformSettingsInfo,
      gdprSettings: platformPublicConfigs?.platformGdprSettings,
      showCookiesBanner: !!platformPublicConfigs?.showCookiesBanner,
      accountEditEnabled: platformPublicConfigs?.loginInfo?.loginType === LoginTypeEnum.Default,
      languages,
      isHrisEnabled: !!platformPublicConfigs?.featureStates?.find((feature) => feature.name === FeatureTypeEnum.Hris)
        ?.enabled,
      isQhrEnabled: !!platformPublicConfigs?.featureStates?.find(
        (feature) => feature.name === FeatureTypeEnum.QhrPrivacy,
      )?.enabled,
    }),
    [
      platformPublicConfigs?.featureStates,
      platformPublicConfigs?.customerId,
      platformPublicConfigs?.hrisIssueNoticeMessage,
      platformPublicConfigs?.loginInfo,
      platformPublicConfigs?.platformPublicConfiguration,
      platformPublicConfigs?.platformSettingsInfo,
      platformPublicConfigs?.platformGdprSettings,
      platformPublicConfigs?.showCookiesBanner,
      languages,
    ],
  );

  const privateConfigs = useMemo(
    () => ({
      customPlatformMessaging: platformPrivateConfigs?.customPlatformMessagingConfiguration?.platformWelcomeMessage
        ? JSON.parse(platformPrivateConfigs?.customPlatformMessagingConfiguration?.platformWelcomeMessage)
        : null,
      linkedinEnabled:
        !!platformPrivateConfigs?.platformPrivateConfiguration?.profilePictureConfiguration?.linkedinImport,
      sessionInactivityTimeout: platformPrivateConfigs?.platformPrivateConfiguration?.sessionInactivityTimeout,
      vps: {
        description: platformPrivateConfigs?.vpsConfiguration?.description,
        enabled: !!platformPrivateConfigs?.vpsConfiguration?.show,
        required: !!platformPrivateConfigs?.vpsConfiguration?.required,
      },
      resourceLibrary: platformPrivateConfigs?.resourceLibrarySettings,
      resourceLibraryBasePath: platformPrivateConfigs?.resourceLibraryBasePath,
      roiBoosted: !!platformPrivateConfigs?.roiBoostEnabled,
      learningLab: {
        enabled: Object.values(platformPrivateConfigs?.learningLabSettings?.settings || {}).some(
          (item) => item !== LearningLabSettingsEnum.disabled,
        ),
        mentorLabEnabled:
          platformPrivateConfigs?.learningLabSettings?.settings[LabsEnum.MentorLab] !==
          LearningLabSettingsEnum.disabled,
        inclusionLabEnabled:
          platformPrivateConfigs?.learningLabSettings?.settings[LabsEnum.InclusionLab] !==
          LearningLabSettingsEnum.disabled,
        leadershipLabEnabled:
          platformPrivateConfigs?.learningLabSettings?.settings[LabsEnum.LeadershipLab] !==
          LearningLabSettingsEnum.disabled,
      },
      profilePictureEnabled:
        !!platformPrivateConfigs?.platformPrivateConfiguration?.profilePictureConfiguration?.enabled,
      diverst: {
        link: generateUrl(platformPrivateConfigs?.diverstLinkConfiguration?.link ?? ''),
        enabled: !!platformPrivateConfigs?.diverstLinkConfiguration?.enabled,
      },
    }),
    [
      platformPrivateConfigs?.customPlatformMessagingConfiguration?.platformWelcomeMessage,
      platformPrivateConfigs?.platformPrivateConfiguration?.profilePictureConfiguration?.linkedinImport,
      platformPrivateConfigs?.platformPrivateConfiguration?.profilePictureConfiguration?.enabled,
      platformPrivateConfigs?.platformPrivateConfiguration?.sessionInactivityTimeout,
      platformPrivateConfigs?.vpsConfiguration,
      platformPrivateConfigs?.resourceLibrarySettings,
      platformPrivateConfigs?.resourceLibraryBasePath,
      platformPrivateConfigs?.roiBoostEnabled,
      platformPrivateConfigs?.learningLabSettings,
      platformPrivateConfigs?.diverstLinkConfiguration?.link,
      platformPrivateConfigs?.diverstLinkConfiguration?.enabled,
    ],
  );

  return useMemo(
    () => ({
      privateConfigs,
      publicConfigs,
    }),
    [privateConfigs, publicConfigs],
  );
};
