import * as React from 'react';
import { ElementType, ReactElement, ReactNode } from 'react';

import { FormattedMessage, MessageDescriptor } from 'react-intl';

type AppFormatXMLElementFn<T, R = string | T | (string | T)[]> = (parts: Array<string | T>) => R;
type AppFormattedMessageValue = Record<
  string,
  string | number | boolean | null | Date | ReactNode | AppFormatXMLElementFn<ReactNode, ReactNode>
>;

interface AppFormattedMessageProps<V extends AppFormattedMessageValue = AppFormattedMessageValue>
  extends MessageDescriptor {
  values?: V;
  tagName?: ElementType;
  children?(nodes: ReactNode[]): ReactElement | null;
}

const AppFormattedMessage = <V extends AppFormattedMessageValue = AppFormattedMessageValue>(
  props: AppFormattedMessageProps<V>,
) => <FormattedMessage {...props} />;

export default AppFormattedMessage;
