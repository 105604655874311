import { ReactNode, useEffect, useMemo, useState } from 'react';

import { FormikConfig, FormikValues, useFormik } from 'formik';

import { useAppIntl } from 'hooks/useAppIntl';

interface AppFormikConfig<T> extends FormikConfig<T> {
  feedbackSelector?: string;
}

export const useAppFormik = <T extends FormikValues>({
  feedbackSelector = '[data-feedback="invalid"]',
  ...config
}: AppFormikConfig<T>) => {
  const formik = useFormik<T>(config);
  const intl = useAppIntl();
  const { errors, isSubmitting, isValid } = formik;

  useEffect(() => {
    if (isSubmitting && !isValid && feedbackSelector) {
      const feedback = document.querySelector(feedbackSelector);

      feedback?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [isSubmitting, isValid, feedbackSelector]);

  const translatedErrors = useMemo(() => {
    const translateObject = (items: any) => {
      if (typeof items === 'object') {
        const clonedObj = { ...items };
        const entries = Object.entries(clonedObj);

        entries.forEach(([key, item]) => {
          if (item) {
            if (typeof item === 'object') {
              if ('definition' in item && item.definition) {
                if ('values' in item && item.values) {
                  clonedObj[key] = intl.formatMessage(item.definition, item.values);
                } else {
                  clonedObj[key] = intl.formatMessage(item.definition);
                }
              } else {
                clonedObj[key] = translateObject(item);
              }
            }
          } else {
            clonedObj[key] = item;
          }
        });

        return clonedObj;
      }
      return errors;
    };

    return translateObject(errors);
  }, [errors, intl]);

  return useMemo(
    () => ({
      ...formik,
      errors: translatedErrors,
    }),
    [formik, translatedErrors],
  );
};

export const useAppErrors = (initial?: Record<string, ReactNode | null>) => {
  const initialized = useMemo(() => initial || {}, [initial]);
  return useState<typeof initialized>(initialized);
};
