import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import { MQTitleProps } from '../types';

const MQTitle: FC<MQTitleProps> = ({
  children,
  type = 'h1',
  className = '',
  bold = false,
  underline = false,
  center = false,
  italic = false,
  nowrap = false,
  mixed = false,
  dataTestId = '',
  innerRef,
  ...props
}) => {
  const HtmlTag = type;

  return (
    <HtmlTag
      data-testid={dataTestId || 'mq-title'}
      className={classNames(['mq-title', 'title', type, className, { nowrap, mixed, bold, center, italic, underline }])}
      ref={innerRef}
      {...props}
    >
      {children}
    </HtmlTag>
  );
};

export default MQTitle;
