import { FC, useCallback, useEffect } from 'react';

import { getWidgetAccessThunk } from '@mentorcliq/storage';

import ROUTES from 'routes';

import { APP_ENV_CONFIGS } from 'definitions/configs';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppQuery } from 'hooks/useAppQuery';

interface WebWidgetProps {
  name?: string | null;
  email?: string | null;
}

enum ZendeskEventActionTypes {
  ArticleViewed = 'Help Center Article Viewed',
  WidgetOpened = 'Web Widget Opened',
}

const AppSupport: FC<WebWidgetProps> = ({ name = '', email = '' }) => {
  const dispatch = useAppDispatch();
  const { cliqipedia } = useAppQuery();

  const init = useCallback(() => {
    if (window?.zE) {
      try {
        window?.zE?.('webWidget', 'updateSettings', {
          cookies: true,
          webWidget: {
            color: {
              theme: '#453776',
              launcher: '#453776',
              launcherText: '#fff',
              button: '#0C6EFD',
              resultLists: '#0C6EFD',
              header: '#453776',
              articleLinks: '#0C6EFD',
            },
            authenticate: {
              jwtFn: (callback: (token: string) => void) => {
                dispatch(getWidgetAccessThunk()).then((action) => {
                  if (getWidgetAccessThunk.fulfilled.match(action)) {
                    if (action.payload?.accessToken) {
                      callback(action.payload.accessToken);
                    }
                  }
                });
              },
            },
          },
        });

        window?.zE?.('webWidget', 'prefill', {
          name: {
            value: name,
          },
          email: {
            value: email,
            readOnly: true,
          },
        });

        window?.zE?.('webWidget:on', 'userEvent', (event: ZendeskEventPayload) => {
          const waitForWidget = () => {
            const widget = Array.from(document.querySelectorAll('iframe')).find((item) => item.id === 'webWidget');

            if (widget) {
              const webWidgetWindow = widget.contentWindow;
              const webWidgetDocument = webWidgetWindow?.document;

              if (event.action === ZendeskEventActionTypes.WidgetOpened) {
                webWidgetDocument?.addEventListener('mousedown', (e) => {
                  const eventTarget = e.target;
                  const anchorHref = eventTarget.getAttribute('href');

                  if (
                    anchorHref?.includes(`${APP_ENV_CONFIGS.supportArticlesPath}`) &&
                    !anchorHref.includes(ROUTES.cliqipedia.data.path) &&
                    eventTarget.className &&
                    [1, 2].includes(e.button)
                  ) {
                    eventTarget.setAttribute(
                      'href',
                      ROUTES.cliqipedia.convert({
                        search: {
                          article: `${anchorHref}`,
                        },
                      }),
                    );
                  }
                });
              } else if (event.action === ZendeskEventActionTypes.ArticleViewed) {
                const buttonTags = webWidgetDocument?.querySelectorAll("button[aria-label='View original article']");
                const linkButton = buttonTags && Array.from(buttonTags)[0];

                linkButton?.addEventListener('click', (e) => {
                  e.preventDefault();
                  e.stopImmediatePropagation();

                  window.open(
                    ROUTES.cliqipedia.convert({
                      search: {
                        article: `${APP_ENV_CONFIGS.supportArticlesPath}${event.properties.id}`,
                      },
                    }),
                    '_blank',
                  );
                });

                webWidgetDocument?.addEventListener('mousedown', (e) => {
                  const eventTarget = e.target;
                  const anchorHref = eventTarget.getAttribute('href');

                  if (
                    anchorHref?.includes(`${APP_ENV_CONFIGS.supportArticlesPath}`) &&
                    !anchorHref.includes(ROUTES.cliqipedia.data.path) &&
                    !eventTarget.className
                  ) {
                    eventTarget.setAttribute(
                      'href',
                      ROUTES.cliqipedia.convert({
                        search: {
                          article: `${anchorHref}`,
                        },
                      }),
                    );
                  }
                });
              }
            } else {
              requestAnimationFrame(waitForWidget);
            }
          };
          waitForWidget();
        });
      } catch (e) {
        console.error(e);
      }
    }
  }, [dispatch, name, email]);

  useEffect(() => {
    const snippet = document.getElementById('ze-snippet');

    if (!snippet) {
      const script = document.createElement('script');
      script.async = true;
      script.id = 'ze-snippet';
      script.src = `${APP_ENV_CONFIGS.supportWidgetSrc}`;
      script.addEventListener('load', () => {
        init();
      });
      document.body.appendChild(script);
    }
    try {
      window?.zE?.('webWidget', 'helpCenter:reauthenticate');
      window?.zE?.('webWidget', 'prefill', {
        name: {
          value: name,
        },
        email: {
          value: email,
          readOnly: true,
        },
      });
    } catch (e) {
      console.error(e);
    }
  }, [init, name, email]);

  useEffect(() => {
    if (!cliqipedia) {
      try {
        window?.zE?.('webWidget', 'show');
      } catch (e) {
        console.error(e);
      }
    }

    return () => {
      if (window?.zE) {
        try {
          window?.zE?.('webWidget', 'hide');
          window?.zE?.('webWidget', 'logout');
        } catch (e) {
          console.error(e);
        }
      }
    };
  }, [cliqipedia]);

  return null;
};

export default AppSupport;
