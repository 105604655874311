import { FC } from 'react';

import classNames from 'classnames';

import { MQSidebarProps } from '../../types';

const MQSidebarProvider: FC<MQSidebarProps> = ({ className = '', children, ...props }) => (
  <nav {...props} className={classNames(['mq-sidebar', className])} aria-label="app-sidebar-nav">
    {children}
  </nav>
);

export default MQSidebarProvider;
