import { FeatureTypeEnum, PermissionsEnum } from '@mentorcliq/storage';

import { ProviderTypesEnum } from 'types/access';
import { LayoutSizeTypes, LayoutTypesEnum, RouteTypes } from 'types/route';

import { createRoute } from 'helpers/routes';

export default {
  reports: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ViewRhr],
        },
      },
    },
    data: {
      path: `/reports`,
    },
  }),
  reportsROICalculator: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Banner,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        features: {
          value: [FeatureTypeEnum.RoiReport],
        },
        permissions: {
          value: [PermissionsEnum.RoiCalculator],
        },
        strict: true,
      },
    },
    data: {
      path: `/reports/roi-calculator`,
      exact: true,
    },
  }),
  reportsROICalculatorProfileFieldSummary: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Banner,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        features: {
          value: [FeatureTypeEnum.RoiReport],
        },
        permissions: {
          value: [PermissionsEnum.ViewRoiProfileFieldsSummary],
        },
        strict: true,
      },
    },
    data: {
      path: `/reports/roi-calculator/profile-field-summary`,
      exact: true,
    },
  }),
  roiTrackingReport: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ViewRoiTrackingResults],
        },
        features: {
          value: [FeatureTypeEnum.RoiReport],
        },
        strict: true,
      },
    },
    data: {
      path: `/reports/roi-tracking-report`,
      exact: true,
    },
  }),
  learningLabReport: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ViewLearningLabReport],
        },
        providers: {
          value: [ProviderTypesEnum.LabsEnabled],
        },
        strict: true,
      },
    },
    data: {
      path: `/reports/learning-lab-report`,
      exact: true,
    },
  }),
  peopleReport: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ManageCustomerUsers],
        },
        strict: true,
      },
    },
    data: {
      path: `/reports/people-tracking-report`,
      exact: true,
    },
  }),
  platformReports: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ExportPlatformData],
        },
      },
    },
    data: {
      path: `/reports/platform-report`,
      exact: true,
    },
  }),
  mentorFlixReport: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ViewMentorFlixReport],
        },
        features: {
          value: [FeatureTypeEnum.MentorFlix],
        },
        strict: true,
      },
    },
    data: {
      path: `/reports/mentorflix`,
      exact: true,
    },
  }),
  helpdeskReport: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ViewContentHelpdeskReport],
        },
        features: {
          value: [FeatureTypeEnum.ContentHelpdesk],
        },
        strict: true,
      },
    },
    data: {
      path: `/reports/helpdesk`,
      exact: true,
    },
  }),
  resourceLibraryReport: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        features: {
          value: [FeatureTypeEnum.ResourceLibrary],
        },
        permissions: {
          value: [PermissionsEnum.ManagePlatformResources],
        },
        strict: true,
      },
    },
    data: {
      path: `/reports/resource-library`,
      exact: true,
    },
  }),
  platformRelationshipExceptionReport: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ViewRhr],
        },
      },
    },
    data: {
      path: `/reports/relationship-exception`,
      exact: true,
    },
  }),
  programLogsReport: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Sidebar,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
      ],
      layoutSize: LayoutSizeTypes.lg,
      types: [RouteTypes.Admin, RouteTypes.Authenticated, RouteTypes.GdprAccepted, RouteTypes.Enrolled],
      access: {
        permissions: {
          value: [PermissionsEnum.ViewProgramLogs],
        },
      },
    },
    data: {
      path: `/reports/program-logs`,
      exact: true,
    },
  }),
};
