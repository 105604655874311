import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import { MQLoaderDotsProps } from '../../types';

const MQLoaderDots: FC<MQLoaderDotsProps> = ({ size = 'md' }) => (
  <span className={classNames('mq-loader-dots', size)}>
    <span className="mq-loader-dots__dot" />
    <span className="mq-loader-dots__dot" />
    <span className="mq-loader-dots__dot" />
  </span>
);

export default MQLoaderDots;
