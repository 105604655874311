import './style.scss';

import { FC, useMemo, useState } from 'react';

import {
  FeatureTypeEnum,
  getBrandingLogoConfigsSelector,
  getIntlLocaleSelector,
  getLogoutThunk,
  PermissionsEnum,
  switchAuthMode,
  switchToUserThunk,
} from '@mentorcliq/storage';
import { MQBadge, MQAnimate } from '@mentorcliq/ui';
import { FormattedMessage } from 'react-intl';

import ROUTES from 'routes';

import { APP_GLOBAL_MESSAGES } from 'definitions/messages';

import history from 'helpers/history';

import { useAppAuthState } from 'hooks/useAppAuthState';
import { useAppConfigs } from 'hooks/useAppConfigs';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppIntl } from 'hooks/useAppIntl';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppSocket } from 'hooks/useAppSocket';
import { useAppViewAs } from 'hooks/useAppViewAs';
import { useAppViewport } from 'hooks/useAppViewport';

import MQAvatar from 'modules/MQAvatar';
import MQButton from 'modules/MQButton';
import MQIcon from 'modules/MQIcon';
import MQImage from 'modules/MQImage';
import MQLayout from 'modules/MQLayout';
import MQNav from 'modules/MQNav';
import MQTypo from 'modules/MQTypo';

import AppLink from 'router/AppLink';

import PermissionWrapper from 'components/PermissionWrapper';
import SwitchLangModal from 'components/SwitchLangModal';
import SwitchUserModal from 'components/SwitchUserModal';

import AppHeaderPreview from './preview';

const AppHeader: FC = () => {
  const intl = useAppIntl();
  const dispatch = useAppDispatch();
  const { publicConfigs, privateConfigs } = useAppConfigs();
  const { unread } = useAppSocket();
  const { isTabletState } = useAppViewport();
  const { user, viewer, profile, administrator } = useAppAuthState();
  const { viewingUserId } = useAppViewAs();
  const logoConfigs = useAppSelector(({ branding }) => getBrandingLogoConfigsSelector(branding));
  const intlLocale = useAppSelector(({ intl }) => getIntlLocaleSelector(intl));

  const [toggle, setToggle] = useState(false);
  const [expended, setExpended] = useState(false);
  const [showSwitchUser, setShowSwitchUser] = useState(false);
  const [showSwitchLanguage, setShowSwitchLanguage] = useState(false);

  const handleSignOut = () => {
    dispatch(getLogoutThunk()).then(() => {
      history.mq.push(ROUTES.mainPage.data.path);
    });
  };

  const handleSwitchToUser = (id: number) => {
    dispatch(
      switchToUserThunk({
        userId: id,
      }),
    ).then(() => {
      history.mq.push(ROUTES.mainPage.data.path);
    });
  };

  const handleOpenProfile = () => {
    history.mq.push(
      ROUTES.profile.convert({
        path: {
          userId: user?.id,
        },
      }),
    );
  };

  const handleToggleAccess = () => {
    dispatch(
      switchAuthMode({
        adminMode: !user?.adminMode,
      }),
    ).then(() => {
      history.mq.push(ROUTES.mainPage.data.path);
    });
  };

  const handleOpenPrivacy = () => {
    history.mq.push(ROUTES.privacy.data.path);
  };

  const handleOpenSupport = () => {
    history.mq.push(ROUTES.support.data.path);
  };

  const logo = useMemo(() => {
    if (isTabletState) {
      return {
        src: logoConfigs?.mobile?.fileHandle ?? logoConfigs?.main?.fileHandle ?? '',
        branded: !!logoConfigs?.displayMobile,
      };
    }

    return {
      src: logoConfigs?.main?.fileHandle ?? logoConfigs?.mobile?.fileHandle ?? '',
      branded: !!logoConfigs?.displayDefault,
    };
  }, [
    isTabletState,
    logoConfigs?.mobile?.fileHandle,
    logoConfigs?.main?.fileHandle,
    logoConfigs?.displayMobile,
    logoConfigs?.displayDefault,
  ]);

  const lang = useMemo(
    () => ({
      label: intlLocale,
      enabled: publicConfigs.languages.list.length > 1,
    }),
    [publicConfigs.languages.list, intlLocale],
  );

  return (
    <div className="app-header">
      {!!viewer && (
        <div className="app-header__section" data-preview="hidden">
          <div className="app-header__line">
            <MQLayout.Heading variant="neutral" compact>
              <MQTypo.Text data-testid="view-mentorcliq-as" bold>
                <FormattedMessage
                  defaultMessage="Signed in as {userName}"
                  id="appHeader.signed.mentorcliQ.as"
                  description="[Header] score"
                  values={{
                    userName: profile?.name,
                  }}
                />
              </MQTypo.Text>
            </MQLayout.Heading>
            <MQButton
              dataTestId="app-header-cancel"
              onClick={handleSignOut}
              startIcon={<MQIcon.Svg icon="times-circle" />}
            >
              <FormattedMessage {...APP_GLOBAL_MESSAGES.cancel} />
            </MQButton>
          </div>
        </div>
      )}
      <div className="app-header__section">
        <MQNav.Navbar className="app-header__navbar" expand="lg" onToggle={setToggle} onExpended={setExpended}>
          <MQNav.Brand className="app-header__brand" isBrand={logo.branded}>
            <AppLink to={ROUTES.mainPage.data.path} className="app-header__logo">
              <MQImage
                src={logo.src}
                data-testid="header-logo"
                alt={intl.formatMessage({
                  defaultMessage: 'MentorcliQ',
                  id: 'app.header.image.alt',
                  description: '[AppHeader] image alt',
                })}
                contain
              />
            </AppLink>
          </MQNav.Brand>

          {!!user && (
            <MQNav className="app-header__navbar-menu">
              {expended && (
                <MQNav.Toggle data-testid="app-header-toggle" className="app-header__toggle">
                  {toggle ? (
                    <MQIcon.Svg
                      aria-label="Close Menu"
                      className="app-header-toggle__close"
                      size="2x"
                      icon="times-alt"
                    />
                  ) : (
                    <MQIcon.Svg aria-label="Open Menu" className="app-header-toggle__open" size="2x" icon="bars" />
                  )}
                </MQNav.Toggle>
              )}
              <MQNav.Collapse data-testid="app-header-collapse" className="app-header__navbar-collapse">
                <MQNav>
                  <MQNav.Link
                    as={AppLink}
                    to={ROUTES.myCliQ.convert({
                      search: {
                        participantId: viewingUserId,
                      },
                    })}
                    className="app-header__navbar-link"
                    dataTestId="app-header-my-cliq"
                  >
                    <FormattedMessage defaultMessage="My CliQ" description="[Header] My CliQ" id="appHeader.my.cliQ" />
                  </MQNav.Link>

                  {privateConfigs.diverst.enabled && (
                    <MQNav.Link
                      className="app-header__navbar-link"
                      href={privateConfigs.diverst.link}
                      dataTestId="app-header-my-diverst"
                      target="_blank"
                    >
                      <FormattedMessage
                        defaultMessage="My Diverst"
                        description="[Header] My Diverst"
                        id="appHeader.my.diverst"
                      />
                    </MQNav.Link>
                  )}

                  {privateConfigs.learningLab.enabled && (
                    <MQNav.Dropdown
                      variant="secondary"
                      data-testid="app-header-learning-lab-dropdown"
                      triggers={['hover']}
                    >
                      <MQNav.DropdownToggle data-testid="app-header-learning-lab-tooggle">
                        <MQNav.Link
                          as={AppLink}
                          to={ROUTES.labsList.data.path}
                          className="app-header__navbar-link"
                          dataTestId="app-header-learning-lab-link"
                          endIcon={<MQIcon.Svg icon="caret-down" />}
                        >
                          <FormattedMessage
                            defaultMessage="LearningLAB"
                            description="[Header] learning lab"
                            id="appHeader.learningLAB"
                          />
                        </MQNav.Link>
                      </MQNav.DropdownToggle>

                      <MQNav.DropdownMenu>
                        {privateConfigs.learningLab.mentorLabEnabled && (
                          <MQNav.DropdownItem onClick={() => history.mq.push(ROUTES.mentorLab.data.path)}>
                            <FormattedMessage
                              defaultMessage="MentorLAB"
                              description="[Header] learning lab"
                              id="appHeader.mentorLAB"
                            />
                          </MQNav.DropdownItem>
                        )}
                        {privateConfigs.learningLab.inclusionLabEnabled && (
                          <MQNav.DropdownItem onClick={() => history.mq.push(ROUTES.inclusionLab.data.path)}>
                            <FormattedMessage
                              defaultMessage="InclusionLAB"
                              description="[Header] learning lab"
                              id="appHeader.inclusionLAB"
                            />
                          </MQNav.DropdownItem>
                        )}
                        {privateConfigs.learningLab.leadershipLabEnabled && (
                          <MQNav.DropdownItem onClick={() => history.mq.push(ROUTES.leadershipLab.data.path)}>
                            <FormattedMessage
                              defaultMessage="LeadershipLAB"
                              description="[Header] learning lab"
                              id="appHeader.leadershipLAB"
                            />
                          </MQNav.DropdownItem>
                        )}
                      </MQNav.DropdownMenu>
                    </MQNav.Dropdown>
                  )}

                  <PermissionWrapper features={{ value: [FeatureTypeEnum.ResourceLibrary] }}>
                    <MQNav.Link
                      as={AppLink}
                      eventKey="resource-library"
                      to={ROUTES.resourceLibrary.data.path}
                      className="app-header__navbar-link"
                      dataTestId="app-header-resource-library"
                    >
                      <FormattedMessage
                        defaultMessage="Resource Library"
                        description="[Header] Resource Library"
                        id="appHeader.resource.library"
                      />
                    </MQNav.Link>
                  </PermissionWrapper>
                  <PermissionWrapper features={{ value: [FeatureTypeEnum.Chat] }}>
                    <MQNav.Link
                      as={AppLink}
                      data-testid="app-header-chat"
                      className="app-header__navbar-link"
                      to={ROUTES.chat.data.path}
                      endIcon={
                        !!unread && (
                          <MQAnimate.Styles dependencies={unread} animation="scale-up-center">
                            <MQBadge.Info variant="warning" size="sm" circle>
                              {unread}
                            </MQBadge.Info>
                          </MQAnimate.Styles>
                        )
                      }
                    >
                      <FormattedMessage
                        id="app.header.messages.label"
                        description="[AppHeader] Messages label"
                        defaultMessage="Messages"
                      />
                    </MQNav.Link>
                  </PermissionWrapper>
                </MQNav>
              </MQNav.Collapse>
            </MQNav>
          )}

          <MQNav.Group>
            {!!profile && (
              <MQNav.Dropdown className="app-header__profile" triggers={['click']}>
                <MQNav.DropdownToggle data-testid="app-header-profile-toggle" className="app-header__profile__toggle">
                  <MQNav.Link
                    as={AppLink}
                    startIcon={
                      <MQAvatar.Image
                        size="sm"
                        title={profile.name ?? ''}
                        imageUrl={profile.picture ?? ''}
                        dataTestId="header-profile-picture"
                      />
                    }
                    truncate
                    bolder
                  >
                    {!expended && <span className="app-header__profile-real-name">{profile.name}</span>}
                  </MQNav.Link>
                </MQNav.DropdownToggle>
                <MQNav.DropdownMenu data-testid="app-header-profile-menu" className="app-header__profile__menu">
                  <MQNav.DropdownItem
                    data-testid="open-profile"
                    data-preview="disabled"
                    onClick={handleOpenProfile}
                    className="app-header__profile__menu-item"
                    startIcon={<MQIcon.Svg icon="user-circle" className="app-header__profile__menu-item-icon" />}
                  >
                    <span className="app-header__profile__menu-item-title">
                      <FormattedMessage
                        defaultMessage="My Profile"
                        description="[Header] Profile button text"
                        id="appHeader.my.profile"
                      />
                    </span>
                  </MQNav.DropdownItem>
                  {administrator && (
                    <MQNav.DropdownItem
                      data-testid="toggle-admin-mode"
                      data-preview="disabled"
                      className="app-header__profile__menu-item"
                      startIcon={<MQIcon.Svg icon="random" className="app-header__profile__menu-item-icon" />}
                      onClick={handleToggleAccess}
                    >
                      <span className="app-header__profile__menu-item-title">
                        {user?.adminMode ? (
                          <FormattedMessage
                            defaultMessage="Participant View"
                            description="[Header] button text"
                            id="appHeader.participant.view"
                          />
                        ) : (
                          <FormattedMessage
                            defaultMessage="Admin View"
                            description="[Header] button text"
                            id="appHeader.admin.view"
                          />
                        )}
                      </span>
                    </MQNav.DropdownItem>
                  )}

                  <PermissionWrapper permissions={{ value: [PermissionsEnum.SwitchUser] }}>
                    <MQNav.DropdownItem
                      className="app-header__profile__menu-item"
                      data-preview="disabled"
                      data-testid="toggle-switch-user"
                      onClick={() => {
                        setShowSwitchUser(true);
                      }}
                      startIcon={<MQIcon.Svg icon="people-arrows" className="app-header__profile__menu-item-icon" />}
                    >
                      <span className="app-header__profile__menu-item-title">
                        <FormattedMessage
                          defaultMessage="Switch User Account"
                          description="[Header] button text"
                          id="appHeader.switch.mentorcliQ.as"
                        />
                      </span>
                    </MQNav.DropdownItem>

                    {!viewer && (
                      <SwitchUserModal
                        visible={showSwitchUser}
                        hide={() => {
                          setShowSwitchUser(false);
                        }}
                        switchToUser={(id) => {
                          handleSwitchToUser(id);
                        }}
                      />
                    )}
                  </PermissionWrapper>
                  <PermissionWrapper features={{ value: [FeatureTypeEnum.Support] }}>
                    <MQNav.DropdownItem
                      data-testid="support"
                      data-preview="disabled"
                      className="app-header__profile__menu-item"
                      onClick={handleOpenSupport}
                      startIcon={<MQIcon.Svg icon="life-ring" className="app-header__profile__menu-item-icon " />}
                    >
                      <span className="app-header__profile__menu-item-title">
                        <FormattedMessage
                          defaultMessage="Support"
                          description="[Header] button text"
                          id="appHeader.support"
                        />
                      </span>
                    </MQNav.DropdownItem>
                  </PermissionWrapper>
                  <MQNav.DropdownItem
                    data-testid="privacy"
                    data-preview="disabled"
                    className="app-header__profile__menu-item"
                    onClick={handleOpenPrivacy}
                    startIcon={<MQIcon.Svg icon="user-shield" className="app-header__profile__menu-item-icon" />}
                  >
                    <span className="app-header__profile__menu-item-title">
                      <FormattedMessage
                        defaultMessage="Privacy"
                        description="[Header] button text"
                        id="appHeader.privacy"
                      />
                    </span>
                  </MQNav.DropdownItem>
                  <MQNav.DropdownItem
                    className="app-header__profile__menu-item"
                    data-preview="disabled"
                    data-testid="sign-out"
                    onClick={handleSignOut}
                    startIcon={<MQIcon.Svg icon="sign-out" className="app-header__profile__menu-item-icon" />}
                  >
                    <span className="app-header__profile__menu-item-title">
                      <FormattedMessage
                        defaultMessage="Log Out"
                        description="[Header] button text"
                        id="appHeader.log.out"
                      />
                    </span>
                  </MQNav.DropdownItem>
                </MQNav.DropdownMenu>
              </MQNav.Dropdown>
            )}

            {!!profile && lang.enabled && <MQNav.Divider direction="vertical" />}

            {lang.enabled && (
              <MQNav.Link
                as={AppLink}
                dataTestId="app-header-language-toggle"
                className="app-header__language"
                onClick={() => {
                  setShowSwitchLanguage(true);
                }}
                startIcon={<MQIcon.Svg icon="globe-light" className="app-header__language-icon" />}
              >
                <span className="app-header__language-text">{lang.label} </span>
              </MQNav.Link>
            )}
          </MQNav.Group>
        </MQNav.Navbar>
      </div>
      {showSwitchLanguage && <SwitchLangModal show={showSwitchLanguage} hide={() => setShowSwitchLanguage(false)} />}
    </div>
  );
};

export default Object.assign(AppHeader, {
  Preview: AppHeaderPreview,
});
