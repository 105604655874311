export default `<svg aria-hidden="true" role="presentation" xmlns="http://www.w3.org/2000/svg" width="100" height="82.051" viewBox="0 0 100 82.051">
    <g id="No_Results" transform="translate(14251 24263)">
        <path id="Path_5786"
              d="M12.568-112h-76.88A7.692,7.692,0,0,0-72-104.308v46.153a7.692,7.692,0,0,0,7.688,7.692H-33.56L-37.4-35.078H-48.936A2.571,2.571,0,0,0-51.5-32.514a2.571,2.571,0,0,0,2.563,2.564H-2.808A2.571,2.571,0,0,0-.246-32.514a2.571,2.571,0,0,0-2.563-2.564H-14.34l-3.844-15.384H12.568a7.692,7.692,0,0,0,7.688-7.692v-46.153A7.692,7.692,0,0,0,12.568-112ZM-32.119-35.078l2.563-10.256h7.368l2.563,10.256ZM15.13-58.155a2.571,2.571,0,0,1-2.563,2.564h-76.88a2.571,2.571,0,0,1-2.563-2.564v-46.153a2.571,2.571,0,0,1,2.563-2.564h76.88a2.571,2.571,0,0,1,2.563,2.564Z"
              transform="translate(-14176.419 -24151)" fill="#453776" opacity="0.125"/>
        <path id="Path_5785"
              d="M8.893-55.125H-26.673a2.542,2.542,0,0,0-2.54,2.54v7.621h12.7v-5.081H-1.269v25.4H8.893a2.542,2.542,0,0,0,2.54-2.54v-25.4A2.542,2.542,0,0,0,8.893-55.125Zm-29.85,8.177a.715.715,0,0,1-.714.714h-2.382a.715.715,0,0,1-.714-.714V-49.33a.715.715,0,0,1,.714-.714h2.382a.715.715,0,0,1,.714.714ZM6.987-30.435a.715.715,0,0,1-.714.714H3.891a.715.715,0,0,1-.714-.714v-2.382a.715.715,0,0,1,.714-.714H6.273a.715.715,0,0,1,.714.714Zm0-8.256a.715.715,0,0,1-.714.714H3.891a.715.715,0,0,1-.714-.714v-2.382a.715.715,0,0,1,.714-.714H6.273a.715.715,0,0,1,.714.714Zm0-8.256a.715.715,0,0,1-.714.714H3.891a.715.715,0,0,1-.714-.714V-49.33a.715.715,0,0,1,.714-.714H6.273a.715.715,0,0,1,.714.714ZM-6.35-42.423H-36.835a2.542,2.542,0,0,0-2.54,2.54v22.864a2.542,2.542,0,0,0,2.54,2.54H-6.35a2.542,2.542,0,0,0,2.54-2.54V-39.882A2.542,2.542,0,0,0-6.35-42.423Zm-25.4,5.081a2.542,2.542,0,0,1,2.54,2.54,2.542,2.542,0,0,1-2.54,2.54,2.542,2.542,0,0,1-2.54-2.54A2.542,2.542,0,0,1-31.754-37.342ZM-8.89-19.559h-25.4V-22.1l5.081-5.081,2.54,2.54L-16.511-34.8-8.89-27.18Z"
              transform="translate(-14188.304 -24197.633)" fill="#453776" opacity="0.125"/>
        <g id="search" transform="translate(-14197.455 -24243.295)">
            <circle id="Ellipse_227" cx="17.5" cy="17.5" r="17.5" transform="translate(0.805 1)" fill="#fff"
                    opacity="0.75"/>
            <path id="Path_5787"
                  d="M10.135-20.457-.879-31.472a1.077,1.077,0,0,0-.771-.318h-1.2A18.82,18.82,0,0,0,1.743-44.128,18.869,18.869,0,0,0-17.128-63,18.869,18.869,0,0,0-36-44.128,18.869,18.869,0,0,0-17.128-25.257,18.82,18.82,0,0,0-4.789-29.848v1.2a1.115,1.115,0,0,0,.318.771L6.543-16.864a1.09,1.09,0,0,0,1.542,0l2.05-2.05A1.09,1.09,0,0,0,10.135-20.457Zm-27.264-9.155A14.513,14.513,0,0,1-31.645-44.128,14.513,14.513,0,0,1-17.128-58.645,14.513,14.513,0,0,1-2.612-44.128,14.513,14.513,0,0,1-17.128-29.612Z"
                  transform="translate(36 63)" fill="#453776"/>
        </g>
        <path id="Path_5784"
              d="M-15.633-31.758a.941.941,0,0,0,.927.783h.956a.939.939,0,0,0,.945-1.043,7.578,7.578,0,0,0-2.122-4.33,5.5,5.5,0,0,1-1.53-3.1.934.934,0,0,0-.933-.8h-.951a.945.945,0,0,0-.945,1.043,8.426,8.426,0,0,0,2.353,4.892A4.708,4.708,0,0,1-15.633-31.758Zm6.492,0a.941.941,0,0,0,.927.783h.956a.939.939,0,0,0,.945-1.043,7.578,7.578,0,0,0-2.122-4.33,5.5,5.5,0,0,1-1.53-3.1.934.934,0,0,0-.933-.8h-.951a.942.942,0,0,0-.945,1.043,8.426,8.426,0,0,0,2.353,4.892A4.708,4.708,0,0,1-9.14-31.758ZM.186-29.121H-21.145A1.853,1.853,0,0,0-23-27.266v11.129a5.566,5.566,0,0,0,5.565,5.565H-6.306A5.566,5.566,0,0,0-.741-16.136H.186a6.5,6.5,0,0,0,6.492-6.492A6.5,6.5,0,0,0,.186-29.121Zm0,9.274H-.741v-5.565H.186a2.783,2.783,0,0,1,2.782,2.782A2.783,2.783,0,0,1,.186-19.846Z"
              transform="translate(-14228 -24170.539)" fill="#453776" opacity="0.5"/>
    </g>
</svg>`;
