import './style.scss';

import { FC } from 'react';

import { FeatureTypeEnum, PermissionsEnum } from '@mentorcliq/storage';
import { MQBadge } from '@mentorcliq/ui';
import { FormattedMessage } from 'react-intl';

import ROUTES from 'routes';

import { APP_GLOBAL_MESSAGES } from 'definitions/messages';

import MQIcon from 'modules/MQIcon';
import MQSidebar from 'modules/MQSidebar';

import AppFormattedMessage from 'formatters/AppFormattedMessage';

import AppLink from 'router/AppLink';

import PermissionWrapper from 'components/PermissionWrapper';

import Programs from './Addons/components/Programs';

const Dashboard: FC = () => (
  <MQSidebar.List className="app-sidebar__list" data-testid="sidebar-dashboard">
    <MQSidebar.ListItem
      as={AppLink}
      className="app-sidebar__list-item"
      data-testid="sidebar-dashboard-link"
      to={ROUTES.adminDashboard.data.path}
      prefix={<MQIcon.Svg icon="house" />}
    >
      <FormattedMessage defaultMessage="Admin Dashboard" description="[Sidebar] Menu Item Name" />
    </MQSidebar.ListItem>
    <PermissionWrapper permissions={{ value: [PermissionsEnum.AddAProgram] }}>
      <MQSidebar.ListItem
        as={AppLink}
        className="app-sidebar__list-item"
        data-testid="sidebar-program-wizard-link"
        to={ROUTES.programWizardWelcome.data.path}
        prefix={<MQIcon.Svg icon="draw-circle" />}
      >
        <FormattedMessage defaultMessage="Design New Program" description="[Sidebar] Menu Item Name" />
      </MQSidebar.ListItem>
    </PermissionWrapper>
    <PermissionWrapper permissions={{ value: [PermissionsEnum.ViewRhr] }}>
      <MQSidebar.ListItem
        as={AppLink}
        data-testid="sidebar-reports-link"
        to={ROUTES.reports.data.path}
        prefix={<MQIcon.Svg icon="chart-line-up" />}
        overlay={<MQIcon.Svg icon="arrow-circle-right" />}
      >
        <FormattedMessage defaultMessage="Reports" description="[Sidebar] Menu Item Name" />
      </MQSidebar.ListItem>
    </PermissionWrapper>
    <PermissionWrapper permissions={{ value: [PermissionsEnum.ManageCustomerUsers] }}>
      <MQSidebar.ListItem
        as={AppLink}
        data-testid="sidebar-people-link"
        to={ROUTES.people.data.path}
        prefix={<MQIcon.Svg icon="user-group" />}
        overlay={<MQIcon.Svg icon="arrow-circle-right" />}
        postfix={
          <MQBadge.Info size="xs" variant="info">
            <AppFormattedMessage {...APP_GLOBAL_MESSAGES.globalNew} />
          </MQBadge.Info>
        }
      >
        <FormattedMessage defaultMessage="People" description="[Sidebar] Menu Item Name" />
      </MQSidebar.ListItem>
    </PermissionWrapper>
    <PermissionWrapper
      permissions={{
        value: [
          PermissionsEnum.ManagePlatformInfo,
          PermissionsEnum.ViewAddOns,
          PermissionsEnum.UpdateAddOns,
          PermissionsEnum.MentorcliqBranding,
          PermissionsEnum.ManagePlatformResources,
          PermissionsEnum.ManagePlatformConfiguration,
          PermissionsEnum.ManageFeatures,
          PermissionsEnum.ManageDynamicTranslations,
          PermissionsEnum.ManageBaseTranslations,
          PermissionsEnum.ManageProfileSettings,
          PermissionsEnum.ManageProfile,
          PermissionsEnum.ManageHRIS,
          PermissionsEnum.ManageSSOSettings,
          PermissionsEnum.ManageCalendarConfiguration,
          PermissionsEnum.ManageZoomConfiguration,
          PermissionsEnum.ManageCollaborationTools,
        ],
      }}
    >
      <MQSidebar.ListItem
        as={AppLink}
        data-testid="sidebar-configure-link"
        to={ROUTES.configure.data.path}
        prefix={<MQIcon.Svg icon="sliders-h" />}
        overlay={<MQIcon.Svg icon="arrow-circle-right" />}
      >
        <FormattedMessage {...APP_GLOBAL_MESSAGES.platformSettingsTitle} />
      </MQSidebar.ListItem>
    </PermissionWrapper>
    <Programs />
    <PermissionWrapper
      permissions={{ value: [PermissionsEnum.ManageContentHelpdeskData, PermissionsEnum.ManageMentorFlixContent] }}
    >
      <MQSidebar.ListItem>
        <MQSidebar.List>
          <MQSidebar.ListItem>
            <MQSidebar.Heading className="app-sidebar__heading">
              <FormattedMessage defaultMessage="Admin Resources" description="[Sidebar] Menu Item Name" />
            </MQSidebar.Heading>
          </MQSidebar.ListItem>
          <PermissionWrapper
            permissions={{ value: [PermissionsEnum.ManageContentHelpdeskData] }}
            features={{ value: [FeatureTypeEnum.ContentHelpdesk] }}
            strict
          >
            <MQSidebar.ListItem
              as={AppLink}
              className="app-sidebar__list-item"
              data-testid="sidebar-helpdesk-link"
              to={ROUTES.helpdesk.data.path}
              prefix={<MQIcon.Svg icon="cabinet-filing" />}
            >
              <FormattedMessage defaultMessage="Content Helpdesk" description="[Sidebar] Menu Item Name" />
            </MQSidebar.ListItem>
          </PermissionWrapper>
          <PermissionWrapper
            features={{ value: [FeatureTypeEnum.MentorFlix] }}
            permissions={{ value: [PermissionsEnum.ManageMentorFlixContent] }}
            strict
          >
            <MQSidebar.ListItem
              as={AppLink}
              className="app-sidebar__list-item"
              data-testid="sidebar-mentorFlix-link"
              to={ROUTES.mentorFlix.data.path}
              prefix={<MQIcon.Svg icon="film" />}
            >
              <FormattedMessage defaultMessage="MentorFlix" description="[Sidebar] Menu Item Name" />
            </MQSidebar.ListItem>
          </PermissionWrapper>
        </MQSidebar.List>
      </MQSidebar.ListItem>
    </PermissionWrapper>
  </MQSidebar.List>
);

export default Dashboard;
