import './style.scss';

import { FC, useMemo, useState } from 'react';

import { MQAnimate } from '@mentorcliq/ui';

import { useAppSocket } from 'hooks/useAppSocket';
import { useAppViewAs } from 'hooks/useAppViewAs';

import ChatDialog from '../ChatDialog';
import ChatRooms from '../ChatRooms';

const ChatProvider: FC = () => {
  const { nativeUserId, viewingUserId } = useAppViewAs();
  const { chatRooms, chatConfigs, toggleChat, toggleRoom } = useAppSocket();

  const [params, setParams] = useState({
    keyword: '',
  });

  const activeRoom = useMemo(() => chatRooms?.[chatConfigs.roomId], [chatConfigs.roomId, chatRooms]);

  return (
    <div className="app-chat-box">
      {activeRoom ? (
        <MQAnimate.Styles animation="slide-up">
          <ChatDialog
            ownerId={nativeUserId}
            participantId={viewingUserId}
            room={activeRoom}
            closeRoom={() => {
              toggleRoom?.(0);
            }}
            closeChat={() => {
              toggleChat?.(false);
            }}
          />
        </MQAnimate.Styles>
      ) : (
        <MQAnimate.Styles animation="slide-up">
          <ChatRooms
            params={params}
            setParams={(data) => {
              setParams((prev) => ({
                ...prev,
                ...data,
              }));
            }}
            activeId={chatConfigs.roomId}
            ownerId={nativeUserId}
            rooms={chatRooms || {}}
            onOpenRoom={(id) => {
              toggleRoom?.(id);
            }}
            onCloseChat={() => {
              toggleChat?.(false);
            }}
          />
        </MQAnimate.Styles>
      )}
    </div>
  );
};

export default ChatProvider;
