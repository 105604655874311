import { FC, ReactNode, useEffect, useState } from 'react';

import ReactGA from 'react-ga4';

import { APP_ENV_CONFIGS } from 'definitions/configs';

import { useAppConfigs } from 'hooks/useAppConfigs';
import { useAppLayout } from 'hooks/useAppLayout';
import { useAppLocation } from 'hooks/useAppLocation';

interface GoogleAnalyticsWrapperProps {
  children?: ReactNode;
}

const GoogleAnalyticsWrapper: FC<GoogleAnalyticsWrapperProps> = ({ children }) => {
  const { publicConfigs } = useAppConfigs();
  const layout = useAppLayout();
  const location = useAppLocation();
  const [scrolledOnce, setScrolledOnce] = useState(false);

  useEffect(() => {
    if (publicConfigs.platformConfigs?.googleAnalyticsTrackingEnabled) {
      ReactGA.initialize(`${APP_ENV_CONFIGS.googleAnalyticsId}`);
    }
  }, [publicConfigs.platformConfigs?.googleAnalyticsTrackingEnabled]);

  useEffect(() => {
    const container = document.getElementById(layout.wrapperId);

    const onScroll = () => {
      if (container && !scrolledOnce) {
        const scrollTop = container.scrollTop;
        const scrollHeight = container.scrollHeight - container.clientHeight;
        const scrollPercentage = (scrollTop / scrollHeight) * 100;

        if (scrollPercentage > 90) {
          ReactGA.event(
            {
              action: 'scroll',
              category: 'scroll',
            },
            {
              scroll_depth: '90%',
              description: 'User scrolled past 90% of the page',
            },
          );
          setScrolledOnce(true);
        }
      }
    };

    if (container) {
      const isScrollable = container.scrollHeight > container.clientHeight;

      if (isScrollable) {
        container?.addEventListener('scroll', onScroll);
      }
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', onScroll);
      }
    };
  }, [scrolledOnce, layout]);

  useEffect(() => {
    setScrolledOnce(false);
  }, [location.pathname]);

  return children;
};

export default GoogleAnalyticsWrapper;
