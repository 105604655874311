import { FC, ReactNode, useMemo } from 'react';

import { useAppConfigs } from 'hooks/useAppConfigs';

interface ConfigWrapperProps {
  children?: ReactNode;
}

const ConfigWrapper: FC<ConfigWrapperProps> = ({ children }) => {
  const { publicConfigs } = useAppConfigs();

  const styles = useMemo(
    () => publicConfigs.platformConfigs?.siteCssCode ?? '',
    [publicConfigs.platformConfigs?.siteCssCode],
  );

  return (
    <>
      <style dangerouslySetInnerHTML={{ __html: styles }} />
      {children}
    </>
  );
};

export default ConfigWrapper;
