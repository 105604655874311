import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import { MQFormRatingProps } from '../../types';

const MQFormRating: FC<MQFormRatingProps> = ({
  className = '',
  label,
  count,
  value,
  defaultIcon,
  activeIcon,
  onChange,
  dataTestId = 'mq-form-rating',
  disabled,
}) => {
  const range = [...Array(count).keys()];

  return (
    <div className={classNames('mq-form-rating', className)} data-testid={dataTestId}>
      <div className="mq-form-rating__filled">
        {range.map((i) => (
          <button
            key={i + 1}
            onClick={() => onChange?.(i + 1)}
            className={classNames('mq-form-rating__star', `${value >= i + 1 ? 'star-full' : 'star-empty'}`)}
            data-testid="mq-form-rating"
            disabled={disabled}
            type="button"
          >
            {value >= i + 1 ? activeIcon : defaultIcon}
          </button>
        ))}
      </div>

      <div className="badge" data-testid={`badge-${value}`}>
        {label}
      </div>
    </div>
  );
};

export default MQFormRating;
