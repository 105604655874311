import { lazy } from 'react';

import CUSTOM_ROUTES from '__custom/mq-advocateaurorahealth/routes';

import { APP_ENV_CONFIGS } from 'definitions/configs';

const CustomerBoundary = () => {
  if (APP_ENV_CONFIGS.projectId === 'localhost') {
    return {
      CustomQuickcliQsReport: lazy(() => import('__custom/mq-advocateaurorahealth/components/CustomQuickcliQsReports')),
      CustomReportsCard: lazy(() => import('__custom/mq-advocateaurorahealth/components/CustomReportsCard')),
      CustomCookiesPolicy: lazy(() => import('__custom/mq-deloitte-me/components/CustomCookiesPolicy')),

      routes: { ...CUSTOM_ROUTES },
    };
  } else if (APP_ENV_CONFIGS.projectId === 'mq-multidev') {
    return {
      GraduationYearSelect: lazy(() => import('__custom/mq-americamentors/components/GraduationYearSelect')),
      GraduationYearLabel: lazy(() => import('__custom/mq-americamentors/components/GraduationYearLabel')),
      CustomQuickcliQsReport: lazy(() => import('__custom/mq-advocateaurorahealth/components/CustomQuickcliQsReports')),
      CustomReportsCard: lazy(() => import('__custom/mq-advocateaurorahealth/components/CustomReportsCard')),
      CustomCookiesPolicy: lazy(() => import('__custom/mq-deloitte-me/components/CustomCookiesPolicy')),

      routes: { ...CUSTOM_ROUTES },
    };
  } else if (APP_ENV_CONFIGS.projectId === 'mq-americamentors') {
    return {
      GraduationYearSelect: lazy(() => import('__custom/mq-americamentors/components/GraduationYearSelect')),
      GraduationYearLabel: lazy(() => import('__custom/mq-americamentors/components/GraduationYearLabel')),
    };
  } else if (APP_ENV_CONFIGS.projectId === 'mq-advocateaurorahealth') {
    return {
      CustomQuickcliQsReport: lazy(() => import('__custom/mq-advocateaurorahealth/components/CustomQuickcliQsReports')),
      CustomReportsCard: lazy(() => import('__custom/mq-advocateaurorahealth/components/CustomReportsCard')),
      routes: { ...CUSTOM_ROUTES },
    };
  } else if (APP_ENV_CONFIGS.projectId === 'mq-deloitte-me') {
    return {
      CustomCookiesPolicy: lazy(() => import('__custom/mq-deloitte-me/components/CustomCookiesPolicy')),
    };
  }

  return { routes: {} };
};

export default CustomerBoundary;
