import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import { MQParagraphProps } from '../types';

const MQParagraph: FC<MQParagraphProps> = ({
  className = '',
  nowrap = false,
  mixed = false,
  italic = false,
  underline = false,
  center = false,
  bold = false,
  dataTestId = '',
  ...props
}) => (
  <p
    data-testid={dataTestId || 'mq-paragraph'}
    className={classNames(['mq-paragraph', 'paragraph', className, { nowrap, bold, center, italic, mixed, underline }])}
    {...props}
  />
);

export default MQParagraph;
