import './style.scss';

import { FC, useCallback, useEffect, useRef, useState } from 'react';

import {
  getSwitchUserCandidates,
  getSwitchUserCandidatesLoadingState,
  getSwitchUserCandidatesTotalElements,
  switchUserCandidatesQueryThunk,
  switchUserCandidatesSlice,
} from '@mentorcliq/storage';
import { debounce } from 'lodash';

import { PAGEABLE_SIZE } from 'definitions/configs';
import { APP_GLOBAL_MESSAGES } from 'definitions/messages';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';

import MQAvatar from 'modules/MQAvatar';
import MQButton from 'modules/MQButton';
import MQForm from 'modules/MQForm';
import MQGrid from 'modules/MQGrid';
import MQIcon from 'modules/MQIcon';
import MQLayout from 'modules/MQLayout';
import MQLoader from 'modules/MQLoader';
import MQModal from 'modules/MQModal';
import MQTable from 'modules/MQTable';
import MQTooltip from 'modules/MQTooltip';
import MQTypo from 'modules/MQTypo';

import AppFormattedMessage from 'formatters/AppFormattedMessage';

interface SwitchUserModalProps {
  visible: boolean;
  hide: () => void;
  switchToUser: (id: number) => void;
}

const SwitchUserModal: FC<SwitchUserModalProps> = ({ visible, switchToUser, hide }) => {
  const dispatch = useAppDispatch();
  const candidatesLoadingState = useAppSelector(({ switchUserCandidates }) =>
    getSwitchUserCandidatesLoadingState(switchUserCandidates),
  );
  const candidatesTotalCount = useAppSelector(({ switchUserCandidates }) =>
    getSwitchUserCandidatesTotalElements(switchUserCandidates),
  );
  const candidates = useAppSelector(({ switchUserCandidates }) => getSwitchUserCandidates(switchUserCandidates));

  const [keyword, setKeyword] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const debounceSearch = useRef(
    debounce((keyword: string, pageNumber: number) => {
      if (keyword.length > 2) {
        dispatch(
          switchUserCandidatesQueryThunk({
            keyword: keyword,
            pageable: {
              pageSize: PAGEABLE_SIZE * pageNumber,
            },
          }),
        );
      } else {
        dispatch(switchUserCandidatesSlice.actions.clear());
      }
    }, 1000),
  ).current;

  useEffect(() => {
    debounceSearch.cancel();
    debounceSearch(keyword, pageNumber);

    return () => {
      debounceSearch.cancel();
    };
  }, [keyword, pageNumber, debounceSearch]);

  const handleOnSearch = (keyword: string) => {
    setKeyword(keyword);
    debounceSearch(keyword, pageNumber);
  };

  const scrollToBottom = useCallback(() => {
    if (pageNumber * PAGEABLE_SIZE < candidatesTotalCount) {
      setPageNumber(pageNumber + 1);
    }
  }, [pageNumber, candidatesTotalCount]);

  useEffect(
    () => () => {
      dispatch(switchUserCandidatesSlice.actions.clear());
    },
    [dispatch],
  );

  return (
    <MQModal show={visible} scrollable centered onHide={hide}>
      <MQModal.Header closeButton>
        <MQModal.Title>
          <AppFormattedMessage
            defaultMessage="Switch User Account"
            description="[SwitchUserModal] Title"
            id="switch.user.modal.title"
          />
        </MQModal.Title>
      </MQModal.Header>

      <MQModal.Body>
        <div className="switch-user">
          <MQGrid.FlexBox direction="column">
            <MQForm.Group>
              <MQForm.Label htmlFor="keyword">
                <AppFormattedMessage
                  defaultMessage="Search for user by name/email"
                  description="[SwitchUserModal] Search label"
                  id="switch.user.modal.search.label"
                />
              </MQForm.Label>
              <MQForm.Input
                disabled={candidatesLoadingState.isPending}
                name="keyword"
                id="keyword"
                autoComplete="off"
                onChange={(e) => handleOnSearch(e.target.value)}
                value={keyword}
              />
            </MQForm.Group>
            {candidates && (
              <div
                className="switch-user__candidates"
                onScroll={(e) => {
                  if (e.currentTarget.clientHeight + e.currentTarget.scrollTop >= e.currentTarget.scrollHeight) {
                    scrollToBottom();
                  }
                }}
              >
                <MQTable borderless align="middle" inline striped stretched>
                  <tbody>
                    {candidates.map((candidate) => (
                      <tr key={candidate.id}>
                        <td>
                          <MQGrid.FlexBox align="center">
                            <MQAvatar.Image
                              size="xs"
                              title={candidate.realName}
                              imageUrl={candidate.profile.profilePictureFileHandle}
                            />
                            <MQLayout.Heading variant="neutral">
                              <MQTypo.Text nowrap>{`${candidate.realName} (${candidate.email})`}</MQTypo.Text>
                            </MQLayout.Heading>
                          </MQGrid.FlexBox>
                        </td>
                        <td>
                          <MQGrid.FlexBox justify="end">
                            <MQTooltip
                              placement="top"
                              overlay={
                                <AppFormattedMessage
                                  defaultMessage="Switch to use MentorcliQ as selected user."
                                  description="[SwitchUserModal] Switch button tooltip"
                                id="switch.user.modal.switch.button.tooltip"
                                />
                              }
                            >
                              <MQButton
                                dataTestId="switch-user-modal-change"
                                variant="secondary"
                                shape="square"
                                startIcon={<MQIcon.Svg icon="exchange-alt" />}
                                onClick={() => switchToUser(candidate.id)}
                              />
                            </MQTooltip>
                          </MQGrid.FlexBox>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  {!candidates?.length && (
                    <caption>
                      <AppFormattedMessage
                        defaultMessage="No results found"
                        description="[SwitchUserModal] No result text"
                        id="switch.user.modal.no.result"
                      />
                    </caption>
                  )}
                </MQTable>
              </div>
            )}

            {candidatesLoadingState.isPending && <MQLoader.Spinner />}
          </MQGrid.FlexBox>
        </div>
      </MQModal.Body>
      <MQModal.Footer>
        <MQButton dataTestId="switch-user-modal-cancel" variant="tertiary" onClick={hide}>
          <AppFormattedMessage {...APP_GLOBAL_MESSAGES.cancel} />
        </MQButton>
      </MQModal.Footer>
    </MQModal>
  );
};

export default SwitchUserModal;
