import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import { MQCaptionProps } from '../types';

const MQCaption: FC<MQCaptionProps> = ({
  className = '',
  bold = false,
  italic = true,
  mixed = false,
  center = false,
  underline = false,
  nowrap = false,
  dataTestId = '',
  ...props
}) => (
  <p
    data-testid={dataTestId || 'mq-caption'}
    className={classNames(['mq-caption', 'caption', className, { nowrap, mixed, bold, center, italic, underline }])}
    {...props}
  />
);

export default MQCaption;
